.image-div {
    width: 30%;
    margin: 15px 0 0 0;
    img {
        width: 100%;
    }
}
.image-div-2 {
    width: 60%;
    margin: 15px 0 0 0;
    img {
        width: 48%;
    }
}
.image-div-3 {
    width: 94%;
    margin: 15px 0 0 0;
    img {
        width: 31%;
    }
}
.image-div-4 {
    width: 94%;
    margin: 15px 0 0 0;
    img {
        width: 23%;
    }
}
.justify-content-sp-evenly {
    justify-content: space-evenly;
}
.description {
    color: #005577;
}